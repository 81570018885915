import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import photo1 from "./img/Sandi-close-up1.jpg";
import photo2 from './img/Sandi-cabin-bg1.jpg';
import photo3 from './img/sandieWorking1.jpeg';
// import photo4 from './img/Sandi-in-sand-script.jpg';
import photo4 from './img/Sandi-Laughing1.jpg';
// import photo5 from './img/Sandi-Name.jpg';
import photo5 from './img/Sandi-on-a-horse1.jpg';
// import photo7 from './img/Sandi-on-horse.jpg';
// import photo9 from './img/Sandi-peace-sign.jpg';
// import photo9 from './img/Sandi-profile.JPG';
import photo6 from './img/sandi-seahawk1.jpg';
import photo7 from './img/Sandi-vines-bg1.jpg';

// const imgStyle= {
//     padding: '10px',
//     width: "200px",
//     height: "300px",
     
// }

function AboutWheel() {
    return (
<Carousel style={{ paddingTop: "auto", margin: "auto" }} width={280} dynamicHeight={false} showArrows={true} autoPlay={true} interval={2400} infiniteLoop={true} speed={180} >
            {/* // showArrows={true}
            // autoplay={true}
            // interval= {1}
            // autoplaySpeed={1}
            // slidesToShow={2}
            // slidesToScroll={1}
            // // speed={180}
            // cssEase='cubic-bezier(.84, 0, .08, .99)' */}


            <div style={{ paddingTop: "100px", paddingBottom: "10px", margin: "auto" }}>
                <img
                className= "imgStyle"
                    src={photo1}
                    alt="Owner"
                    // style={imgStyle}
                />
                <p className="legend">The Boss!</p>
            </div>
            <div style={{ paddingTop: "100px", paddingBottom: "10px", margin: "auto" }}>
                <img
                className= "imgStyle"
                src={photo2}
                    alt="Owner"
                    // style={imgStyle}
                />
                <p className="legend">Relaxing on the porch.</p>
            </div>
            <div style={{ paddingTop: "100px", paddingBottom: "10px", margin: "auto" }}>
                <img 
                className= "imgStyle"
                src={photo3}
                    alt="Owner"
                    // style={imgStyle}
                />
                <p className="legend">On the Job.</p>
            </div>
            {/* <div>
                <img 
                src={photo4}
                    alt="Owner"
                    style={imgStyle}
                />
                <p className="legend">Getting sandy with Sandi.</p>
            </div> */}
            <div style={{ paddingTop: "100px", paddingBottom: "10px", margin: "auto" }}>
                <img 
                className= "imgStyle"
                src={photo4}
                    alt="Owner"
                    // style={imgStyle}
                />
                <p className="legend">Sense of humor is always a plus.</p>
            </div>
            {/* <div>
                <img 
                className= "imgStyle"
                src={photo5}
                    alt="Owner"
                    style= {{
                      width: "100px",
                      height: "100px"
                    }}
                />
                <p className="legend">Sandy script.</p>
            </div> */}
            <div style={{ paddingTop: "100px", paddingBottom: "10px", margin: "auto" }}>
                <img 
                className= "imgStyle"
                src={photo5}
                    alt="Owner"
                    // style={imgStyle}
                />
                <p className="legend">Liesurly time.</p>
            </div>
            {/* <div>
                <img 
                src={photo7}
                    alt="Owner"
                    style={imgStyle}
                />
                <p className="legend">Favorite pass time!!!!</p>
            </div> */}
            {/* <div>
                <img 
                src={photo9}
                    alt="Owner"
                    style={imgStyle}
                />
                <p className="legend">Professional and courteous.</p>
            </div> */}
            <div style={{ paddingTop: "100px", paddingBottom: "10px", margin: "auto" }}>
                <img 
                className= "imgStyle"
                src={photo6}
                    alt="Owner"
                    // style={imgStyle}
                />
                <p className="legend">Go HAWKS!!!!</p>
            </div>
            <div style={{ paddingTop: "100px", paddingBottom: "10px", margin: "auto" }}>
                <img 
                className= "imgStyle"
                src={photo7}
                    alt="Owner"
                    // style={imgStyle}
                />
                <p className="legend">Who doesnt love some local grapes?</p>
            </div>
        </Carousel>

    );
}

export default AboutWheel;
