import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Local, Affordable, Licensed, Bonded, Insured, Master Electrician, Remodel, New Installations, Services, Meters, Panels, EV Chargers", 
             ],
        autoStart: true,
        loop: true,
        deleteSpeed: 3,
        delay: 50,
      }}
    />
  );
}

export default Type;
