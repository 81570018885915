//background and colors not meshing
import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Form from "../Form";
import ScrollToTop from "../ScrollToTop";
import "../../css/style.css";
// import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { number } from "prop-types";

/**
@config
*/

const config = {
  api: `${process.env.REACT_APP_API}`,
  title: 'SHORT STOP ELECTRICAL LLC',
  description: 'Call 1(425)495-7161 or leave us a detailed message below.',
  successMessage: 'Thank you for contacting us.',
  errorMessage: 'Please fill the complete form',
  fields:{
    firstName: '',
    lastName: '',
    email: '',
    msg: ''
  },
  fieldsConfig:  [
   { id: 1, label: 'First Name', fieldName: 'firstName', type: 'text',placeholder:'Your First Name', isRequired: true , klassName:'first-name-field'},
   { id: 2, label: 'Last Name', fieldName: 'lastName', type: 'text', placeholder: 'Your Last Name', isRequired: true , klassName:'last-name-field'},
   { id: 3, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Your Email', isRequired: true , klassName:'email-field'},
   { id: 4, label: 'Message', fieldName: 'msg', type: 'textarea',placeholder:'Write something.....', isRequired: true , klassName:'message-field'}
  ]
}

function ContactForm() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
      <div className="ContactForm" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
          <Form config={config} />
        <Footer />
      </div>
  );
}

export default ContactForm;
