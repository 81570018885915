import React from 'react';
import ReactDOM from 'react-dom';
import App from '../src/App.js';
import './css/style.css';


ReactDOM.render(
  <div>
  <App style={{ margin: "0", padding: "0" }} />
  <button id="housecall-pro-chat-bubble" src="https://chat.housecallpro.com/proChat.js" type="text/javascript" data-color="#ad5ffb" data-organization="e8e45345-e009-46e8-abe9-41f700d4f494" defer >

  </button>
  </div>
, document.getElementById('root'));
