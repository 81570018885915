//Back ground and colors not meshing
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { FcFlashOn , FcGoogle} from "react-icons/fc";
import ssLogo from "../../Assets/Images/SS_ICON.jpg";
import { AiOutlineFacebook } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Services() {
  return (
    <Container fluid className="services-section" id="services">
      <Container>
        <Row>
          <Col md={8} className="services-description">
            <h1 style={{ fontSize: "4.4em", paddingTop: "60px"}}>
              <span className="yellow"> SOME OF WHAT WE DO</span> AT 
              <br />
              <span className="purple"> SHORT STOP ELECTRIC LLC</span>
            </h1>

           
            <br />
            <ul className= "services-content">
              <li className="services">
                <FcFlashOn />  Panel Replacement and Upgrade
              </li>
              <li className="services">
                <FcFlashOn /> Ev "Electric Vehicle" Charger
              </li>
              <li className="services">
                <FcFlashOn /> Light replacement
              </li>
              <li className="services">
                <FcFlashOn /> Under counter lighting
              </li>
              <li className="services">
                <FcFlashOn /> Ceiling fans
              </li>
              <li className="services">
                <FcFlashOn /> Add an electrical circuit for A/C, hot tub/spa, etc
              </li>
              <li className="services">
                <FcFlashOn /> Bathroom fans
              </li>
              <li className="services">
                <FcFlashOn /> Low voltage, whole house phone and cable systems
              </li>
              <li className="services">
                <FcFlashOn /> Backup Generators
              </li>
              <li className="services">
                <FcFlashOn /> House Rewire
              </li>
              <li className="services">
                <FcFlashOn /> Replace old Wiring
              </li>
              <li className="services">
                <FcFlashOn /> Trouble Shooting Services
                <ul>
                  <li className="services">
                    <FcFlashOn /> Tripping Breakers
                  </li>            <li className="services">
                    <FcFlashOn /> Non working lights, Switches, Outlets
                  </li>            <li className="services">
                    <FcFlashOn /> Burnt or Loose Outlets
                  </li>
                </ul>
              </li>
            </ul>
            <br/>
            <h3 className= "heading">
              <span className="yellow">Let's Take Care of Your Home, You Need it </span>
            </h3>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={ssLogo} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Use the contact page to inquire more about custom projects</h1>
            <p>
              Feel free to <span className="purple">connect </span>with us.
            </p>
            <ul className="social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=100088850304795"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  social-icons"
                >
                  <AiOutlineFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/short-stop-electric-llc-474b01264/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.google.com/localservices/prolist?g2lbs=AIQllVzhbkNIJo9Jx1eB0uaC8nDBbPjMU4hjgd599oT45WtBvkkPfbYbIdRjumdGXNoOe07gsyi5uv3ihaf-Ri7l6Ic1cTaktysnNBKXoziwUTMHtRoLtTA%3D&hl=en-US&gl=us&ssta=1&src=1&gsas=1&slp=Or4DQ2hNSWtOZlYwY3paaEFNVnVNM0NCQjJtRmdwTEVpTUlCQkFCR2d3STAtZnh3Z1VRbktxTTNCRWd2YUhQRWpEZTI1c1RPTjdRcHdsSUFCSWlDQVFRQWhvTENQLUp4bWNRbDViVzNBa2duSVBURVREZTI1c1RPTTdCNlFoSUFCSWpDQVFRQXhvTUNJTGZxOWNERU5pZ3JlSUpJTFRpekEwdzN0dWJFemlhc2VZR1NBQVNJZ2dFRUFRYUN3aXZoYnc0RVBMU29xc0pJTEd4cHhFdzN0dWJFempZMk5NSVNBQVNKZ2dFRUFVYURBanVucHV0QVJEUzhLS3lDU0NyeXJvUk1ON2JteE00bGFYZENFanY3YzBKRWlZSUJCQUdHZ3dJeHFtZW9BVVEwNExQb1JBZ2s0RE5EVERlMjVzVE9JbkE1Z1pJNy0zTkNSSW1DQVFRQnhvTUNQbjM3SVFFRUtXWTFlb0pJTUM2bVFZdzN0dWJFemlnM1l3RFNPX3R6UWtTSlFnRUVBZ2FDd2luNnNGMUVLNndpTEFKSUoyUl94QXczdHViRXpqT3lMOElTT190elFraUJBZ0VFQUlvREE%3D&spp=ElsKEgjT5_HCBRCcqozcERj_x6-gJRIseGNhdDpzZXJ2aWNlX2FyZWFfYnVzaW5lc3NfZWxlY3RyaWNpYW46ZW4tVVNCEwiQ19XRzNmEAxW4zcIEHaYWCktIBFAB&scp=Cix4Y2F0OnNlcnZpY2VfYXJlYV9idXNpbmVzc19lbGVjdHJpY2lhbjplbi1VUxIgGhIJOzXw-Q7KmlQR3UWOAVg_bQAiBTk4MjUyOKGtpwQqCkVsZWN0cmljYWw%3D&q=electrician%20near%20me&ved=2ahUKEwjKtM3RzNmEAxXcJjQIHVwgDYcQlZUCegQIBhAS"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour social-icons"
                >
                  <FcGoogle />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container >
  );
}
export default Services;