// Social links not showing proper size

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SiGodaddy } from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineFacebook } from "react-icons/ai";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-author">
          <h3>Site created by Green Hat Developers</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} GH</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
               
                href="https://www.google.com/localservices/prolist?g2lbs=AIQllVzhbkNIJo9Jx1eB0uaC8nDBbPjMU4hjgd599oT45WtBvkkPfbYbIdRjumdGXNoOe07gsyi5uv3ihaf-Ri7l6Ic1cTaktysnNBKXoziwUTMHtRoLtTA%3D&hl=en-US&gl=us&ssta=1&src=1&gsas=1&slp=Or4DQ2hNSWtOZlYwY3paaEFNVnVNM0NCQjJtRmdwTEVpTUlCQkFCR2d3STAtZnh3Z1VRbktxTTNCRWd2YUhQRWpEZTI1c1RPTjdRcHdsSUFCSWlDQVFRQWhvTENQLUp4bWNRbDViVzNBa2duSVBURVREZTI1c1RPTTdCNlFoSUFCSWpDQVFRQXhvTUNJTGZxOWNERU5pZ3JlSUpJTFRpekEwdzN0dWJFemlhc2VZR1NBQVNJZ2dFRUFRYUN3aXZoYnc0RVBMU29xc0pJTEd4cHhFdzN0dWJFempZMk5NSVNBQVNKZ2dFRUFVYURBanVucHV0QVJEUzhLS3lDU0NyeXJvUk1ON2JteE00bGFYZENFanY3YzBKRWlZSUJCQUdHZ3dJeHFtZW9BVVEwNExQb1JBZ2s0RE5EVERlMjVzVE9JbkE1Z1pJNy0zTkNSSW1DQVFRQnhvTUNQbjM3SVFFRUtXWTFlb0pJTUM2bVFZdzN0dWJFemlnM1l3RFNPX3R6UWtTSlFnRUVBZ2FDd2luNnNGMUVLNndpTEFKSUoyUl94QXczdHViRXpqT3lMOElTT190elFraUJBZ0VFQUlvREE%3D&spp=ElsKEgjT5_HCBRCcqozcERj_x6-gJRIseGNhdDpzZXJ2aWNlX2FyZWFfYnVzaW5lc3NfZWxlY3RyaWNpYW46ZW4tVVNCEwiQ19XRzNmEAxW4zcIEHaYWCktIBFAB&scp=Cix4Y2F0OnNlcnZpY2VfYXJlYV9idXNpbmVzc19lbGVjdHJpY2lhbjplbi1VUxIgGhIJOzXw-Q7KmlQR3UWOAVg_bQAiBTk4MjUyOKGtpwQqCkVsZWN0cmljYWw%3D&q=electrician%20near%20me&ved=2ahUKEwjKtM3RzNmEAxXcJjQIHVwgDYcQlZUCegQIBhAS"
                // style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FcGoogle/>
              </a>
            </li>
            <li className="social-icons">
              <a
                 
                 href="https://seal.godaddy.com/verifySeal?sealID=72tFJ7GjvoTpvzW5hRBZ1h4uSIYikOFHjh6yVclMcAiUEshch1blzUOCTZzL"
                // style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <SiGodaddy />
              </a>
            </li>
            <li className="social-icons">
              <a
               
                href="https://www.linkedin.com/in/short-stop-electric-llc-474b01264/"
                // style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
               
                href="https://www.facebook.com/profile.php?id=100088850304795"
                // style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiOutlineFacebook />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
