import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import AboutWheel from "./AboutWheel"

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "0px" }}>
          <Col
          className="about-row"
            md={7}
            style={{
              // justifyContent: "center",
              // padding: "75px",
              // paddingBottom: "10px",              
            }}
          >
            <h1 style={{ fontSize: "4.1em", paddingTop: "0px", paddingBottom: "0px" }}>
              Get to Know The <strong className="purple">Boss</strong>
            </h1>
            <Aboutcard className="about-card"/>
          </Col>
          <Col
          className="about-wheel"
            md={5}
          >
            <AboutWheel
              style={{
                justifyContent: "center",
              }}
            // className="about-img"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
